<template>
  <div>
    <!-- Loading and Snackbar -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <!-- Vouchers Display -->
    <v-layout wrap justify-center>
      <v-flex lg11>
        <v-layout wrap justify-center pt-10 px-5>
          <v-flex lg6>
            <span style="font-family: Montserrat-Bold; font-size: 28px"
              >Vouchers</span
            >
          </v-flex>
          <v-flex lg6 class="text-end" align-self-end>
            <v-btn color="#00A08E" @click="openDialog('add')">
              <span
                style="
                  font-family: Montserrat-Regular;
                  font-weight: 600;
                  color: white;
                "
                >Add Voucher</span
              >
            </v-btn>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start v-if="vouchers.length > 0">
          <v-flex
            sm4
            md3
            lg3
            pt-4
            v-for="(item, index) in vouchers"
            :key="index"
            class="my-2"
            pl-3
            pr-3
          >
            <v-card class="tile equal-height-card" @click="openDialog('view', item._id, item)">
              <v-layout wrap justify-center pa-5>
                <v-flex xs11 sm11 md11 lg11 align-self-center>
                  <span
                    style="
                      font-family: Montserrat-Regular;
                      font-size: 16px;
                      font-weight: 600;
                      color: black;
                    "
                  >
                    {{ item.name }}
                  </span>
                </v-flex>
                <v-flex xs1 sm1 md1 lg1 class="text-end">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item @click="openDialog('edit', item._id, item)">
                        <v-list-item-title>
                          <span
                            style="font-family: Lato-Regular; font-weight: 400"
                          >
                            <v-icon color="black">mdi-pencil</v-icon>&nbsp; Edit
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openDialog('delete', item._id, item)"
                      >
                        <v-list-item-title>
                          <span
                            style="font-family: Lato-Regular; font-weight: 400"
                          >
                            <v-icon color="black">mdi-delete</v-icon>&nbsp;
                            Delete
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex lg12>
                  <v-layout wrap justify-center>
                    <v-flex md12 lg12 align-self-center>
                      <span
                        style="
                          font-family: Lato-Regular;
                          font-size: 16px;
                          font-weight: 500;
                          color: black;
                        "
                      >
                        {{ item.description }}
                      </span>
                    </v-flex>

                    <v-flex xs12 sm12 md12 lg12 pt-2 class="text-end">
                      <v-btn small style="background-color: #00A08E" v-if="item.isHidden"><span style="color: white;">Hidden</span></v-btn>
                    </v-flex>
                    <!-- <v-flex md12 lg12 align-self-center>
                        <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500; color: black;">
                          {{ item.expiryDate }}
                        </span>
                      </v-flex> -->
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center v-else>
          <v-flex xs8 sm4 md3 lg3 xl6 text-center pt-10>
            <v-img src="../../../assets/images/nodatanew.png"></v-img>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12 xl12 text-center pt-10>
            <span style="font-family: Lato-Regular; font-size: 20px;">No vouchers are available now</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- View Voucher Dialog -->
    <v-dialog
      v-model="viewVoucherDialog"
      width="400px"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex lg12>
            <v-layout wrap justify-center>
              <v-flex lg11 pb-3>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: 700;
                  "
                >
                  View Voucher
                </span>
              </v-flex>
              <v-flex lg1>
                <v-btn icon @click="viewVoucherDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Name</span
                >
                <p style="font-family: Lato-Regular">{{ voucher.name }}</p>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Code</span
                >
                <p style="font-family: Lato-Regular">{{ voucher.code }}</p>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Description</span
                >
                <p style="font-family: Lato-Regular">
                  {{ voucher.description }}
                </p>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Discount Percentage</span
                >
                <p style="font-family: Lato-Regular">
                  {{ voucher.discountPercentage }}%
                </p>
              </v-flex>
              <!-- <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Max Discount Amount</span
                >
                <p style="font-family: Lato-Regular">
                  {{ voucher.maxDiscountAmount }}
                </p>
              </v-flex> -->
              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Min Accounts Required</span
                >
                <p style="font-family: Lato-Regular">
                  {{ voucher.minAccountsRequired }}
                </p>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Max Accounts Required</span
                >
                <p style="font-family: Lato-Regular">
                  {{ voucher.maxAccountsRequired }}
                </p>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Is the voucher hidden?</span
                >
                <p style="font-family: Lato-Regular">{{ voucher.isHidden ? 'Yes' : 'No' }}</p>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 16px;
                    font-weight: 700;
                  "
                  >Status</span
                >
                <p style="font-family: Lato-Regular">{{ voucher.status }}</p>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addVoucherDialog"
      width="400px"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex xs12 sm12 md12 lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: 700;
                  "
                >
                  Add Voucher
                </span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon @click="addVoucherDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <!-- Add Voucher Form Fields -->
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Name</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  placeholder="Voucher Name"
                  v-model="newVoucher.name"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Code</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  placeholder="Voucher Code"
                  v-model="newVoucher.code"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Description</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-textarea
                  outlined
                  dense
                  placeholder="Description"
                  v-model="newVoucher.description"
                  hide-details="auto"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Discount Percentage</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Discount %"
                  v-model="newVoucher.discountPercentage"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Max Discount Amount</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Max Discount Amount"
                  v-model="newVoucher.maxDiscountAmount"
                  hide-details="auto"
                ></v-text-field>
              </v-flex> -->
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Min Accounts Required</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Min Accounts Required"
                  v-model="newVoucher.minAccountsRequired"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Max Accounts Required</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Max Accounts Required"
                  v-model="newVoucher.maxAccountsRequired"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-2 class="d-flex align-center">
                <label for="hide-voucher-checkbox" class="mr-2">Do you want the voucher to be hidden?</label>
                <v-checkbox
                class="ml-2"
                  v-model="newVoucher.isHidden"
                  id="hide-voucher-checkbox"
                  hide-details="auto"
                ></v-checkbox>
              </v-flex>


              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Status</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-select
                  outlined
                  dense
                  :items="['Active', 'Inactive']"
                  v-model="newVoucher.status"
                  hide-details="auto"
                ></v-select>
              </v-flex>

              <!-- Save Button -->
              <v-flex lxs12 sm12 md12 g12 text-center pt-4>
                <v-btn color="#00A08E" @click="addVoucher()">
                  <span
                    style="
                      font-family: Montserrat-Regular;
                      font-weight: 600;
                      color: white;
                    "
                    >Submit</span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- Edit Voucher Dialog -->
    <v-dialog
      v-model="editVoucherDialog"
      width="400px"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex xs12 sm12 md12 lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: 700;
                  "
                >
                  Edit Voucher
                </span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon @click="editVoucherDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <!-- Edit Voucher Form Fields -->
              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Name</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  placeholder="Voucher Name"
                  v-model="voucher.name"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Code</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  placeholder="Voucher Name"
                  v-model="voucher.code"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Description</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-textarea
                  outlined
                  dense
                  placeholder="Description"
                  v-model="voucher.description"
                  hide-details="auto"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Discount Percentage</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Discount %"
                  v-model="voucher.discountPercentage"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Max Discount Amount</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Max Discount Amount"
                  v-model="voucher.maxDiscountAmount"
                  hide-details="auto"
                ></v-text-field>
              </v-flex> -->
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Min Accounts Required</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Min Accounts Required"
                  v-model="voucher.minAccountsRequired"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Max Accounts Required</span
                >
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  placeholder="Max Accounts Required"
                  v-model="voucher.maxAccountsRequired"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-2 class="d-flex align-center">
                <label for="hide-voucher-checkbox" class="mr-2">Do you want the voucher to be hidden?</label>
                <v-checkbox
                class="ml-2"
                  v-model="voucher.isHidden"
                  id="hide-voucher-checkbox"
                  hide-details="auto"
                ></v-checkbox>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                  >Status</span
                >
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-select
                  outlined
                  dense
                  :items="['Active', 'Inactive']"
                  v-model="voucher.status"
                  hide-details="auto"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-flex>

              <!-- Save Button -->
              <v-flex xs12 sm12 md12 lg12 text-center pt-4>
                <v-btn color="#00A08E" @click="editVoucher()">
                  <span
                    style="
                      font-family: Montserrat-Regular;
                      font-weight: 600;
                      color: white;
                    "
                    >Submit</span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- Delete Voucher Dialog -->
    <v-dialog
      v-model="deleteVoucherDialog"
      width="400px"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex lg12>
            <v-layout wrap justify-center>
              <v-flex lg11 pb-3>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: 700;
                  "
                >
                  Delete Voucher
                </span>
              </v-flex>
              <v-flex lg1>
                <v-btn icon @click="deleteVoucherDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>
              <v-flex lg12 text-center>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                >
                  Are you sure you want to delete this voucher?
                </span>
              </v-flex>

              <!-- Delete Confirmation Button -->
              <v-flex lg12 text-center pt-4>
                <v-btn color="#F44336" @click="confirmDeleteVoucher">
                  <span
                    style="
                      font-family: Montserrat-Regular;
                      font-weight: 600;
                      color: white;
                    "
                    >Delete</span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      appLoading: false,
      showsnackbar: false,
      msg: "",
      vouchers: [],
      voucher: {},
      viewVoucherDialog: false,
      editVoucherDialog: false,
      deleteVoucherDialog: false,
      isHidden: false,
      editIsHidden: false,
      voucherId: "",
      addVoucherDialog: false, // Control the visibility of the add voucher dialog
      newVoucher: {
        name: "",
        description: "",
        code: "", // Add if required
        discountPercentage: "",
        maxDiscountAmount: "",
        minAccountsRequired: "",
        maxAccountsRequired: "",
        status: "Active", // Default value
        isHidden: false,
      editIsHidden: false,
      },
    };
  },
  methods: {
    // Fetch vouchers from the backend
    getVouchers() {
      this.appLoading = true;
      axios({
        url: "/voucher/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.vouchers = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Error loading vouchers";
          this.showsnackbar = true;
          console.log(err);
        });
    },

    // Open Dialog Box based on type (view, edit, delete)
    openDialog(type, id, voucher = {}) {
      this.voucher = { ...voucher };
      this.voucherId = id;
      if (type === "view") {
        this.viewVoucherDialog = true;
      } else if (type === "edit") {
        this.editVoucherDialog = true;
      } else if (type === "delete") {
        this.deleteVoucherDialog = true;
      } else if (type === "add") {
        this.addVoucherDialog = true;
      }
    },

    editVoucher() {
      // Show loading spinner or some indication
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/voucher/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.voucherId, // Unique identifier for the voucher
          name: this.voucher.name,
          description: this.voucher.description,
          code: this.voucher.code, // Assuming `code` is a required field
          discountPercentage: this.voucher.discountPercentage,
          isHidden: this.voucher.isHidden,
          // maxDiscountAmount: this.voucher.maxDiscountAmount,
          minAccountsRequired: this.voucher.minAccountsRequired,
          maxAccountsRequired: this.voucher.maxAccountsRequired,
          status: this.voucher.status, // Assuming status is either "Active" or "Inactive"
        },
      })
        .then((response) => {
          // Handle successful response
          if (response.data.status) {
            this.getVouchers(); // Refresh vouchers
            this.editVoucherDialog = false; // Close dialog
            this.msg = "Voucher updated successfully"; // Success message
            this.showsnackbar = true; // Show snackbar
          } else {
            // Handle error message from server
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          // Hide loading spinner
          this.appLoading = false;
        })
        .catch((err) => {
          // Handle errors
          this.ServerError = true;
          this.appLoading = false; // Hide loading spinner
          this.msg = "Error updating voucher"; // Error message
          this.showsnackbar = true; // Show snackbar
          console.log(err); // Log error for debugging
        });
    },

    addVoucherdial() {
      this.addVoucherDialog = true;
    },

    addVoucher() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/voucher/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.newVoucher.name,
          description: this.newVoucher.description,
          code: this.newVoucher.code, // Ensure 'code' is included if required
          discountPercentage: this.newVoucher.discountPercentage,
          isHidden: this.newVoucher.isHidden,
          // maxDiscountAmount: this.newVoucher.maxDiscountAmount,
          minAccountsRequired: this.newVoucher.minAccountsRequired,
          maxAccountsRequired: this.newVoucher.maxAccountsRequired,
          status: this.newVoucher.status,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getVouchers(); // Refresh vouchers list
            this.addVoucherDialog = false; // Close dialog
            this.msg = "Voucher added successfully"; // Success message
            this.showsnackbar = true; // Show snackbar
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.appLoading = false; // Hide loading spinner
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false; // Hide loading spinner
          this.msg = "Error adding voucher"; // Error message
          this.showsnackbar = true; // Show snackbar
          console.log(err); // Log error for debugging
        });
    },

    // Confirm voucher deletion
    confirmDeleteVoucher() {
      this.appLoading = true;
      axios({
        url: "/voucher/delete/" + this.voucherId,
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.getVouchers();
          this.deleteVoucherDialog = false;
          this.appLoading = false;
          this.msg = "Voucher deleted successfully";
          this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Error deleting voucher";
          this.showsnackbar = true;
          console.log(err);
        });
    },
  },

  mounted() {
    this.getVouchers();
  },
};
</script>

<style scoped>
.tile {
  transition: box-shadow 0.25s;
  cursor: pointer;
}

.v-list-item--active {
  background-color: transparent !important; /* Removes the background */
}

.equal-height-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure all cards stretch to the same height */
}


/* .dialog-custom .v-card {
  border-radius: 12px;
} */
</style>